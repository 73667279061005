var app = window.app || {};
app.globals = {
	timeOfDay: function (d) {
		if ("undefined" == typeof d && this.lastTimeOfDay) {
			return this.lastTimeOfDay
		}
		var c = {};
		return c.now = c.morning = c.afternoon = c.lunchtime = c.daytime = c.nighttime = !1, d >= 4 && d < 6 ? c.now = "early" : d >= 6 && d < 8 ? (c.now = "earlymorning", c.morning = !0) : d >= 8 && d < 12 ? (c.now = "latemorning", c.morning = !0) : d >= 12 && d < 13 ? (c.now = "noonish", c.afternoon = !0, c.lunchtime = !0) : d >= 13 && d < 16 ? (c.now = "afternoon", c.afternoon = !0) : d >= 16 && d < 19 ? (c.now = "earlyevening", c.afternoon = !0) : d >= 19 && d < 21 ? c.now = "evening" : d >= 21 && d < 23 ? c.now = "night" : (d >= 23 || d < 4) && (c.now = "latenight"), d >= 6 && d < 19 ? c.daytime = !0 : c.nighttime = !0, this.lastTimeOfDay = c, c
	},
};
var loadedAssetsCounter = 0;
var keys = {
	37: 1,
	38: 1,
	39: 1,
	40: 1
};


function getRootUrl() {
	return window.location.origin ? window.location.origin + "/" : window.location.protocol + "/" + window.location.host + "/"
}

function preventDefault(a) {
	a = a || window.event, a.preventDefault && a.preventDefault(), a.returnValue = !1
}

function preventDefaultForScrollKeys(a) {
	return keys[a.keyCode] ? (preventDefault(a), !1) : void 0
}

function disableScroll() {
	window.addEventListener && window.addEventListener("DOMMouseScroll", preventDefault, !1), window.onwheel = preventDefault, window.onmousewheel = document.onmousewheel = preventDefault, window.ontouchmove = preventDefault, document.onkeydown = preventDefaultForScrollKeys
}

function enableScroll() {
	window.removeEventListener && window.removeEventListener("DOMMouseScroll", preventDefault, !1), window.onmousewheel = document.onmousewheel = null, window.onwheel = null, window.ontouchmove = null, document.onkeydown = null
}

function check_email(a) {
	var b = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
	return b.test(a)
}

function valid_phone(a) {
	var b = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
	return b.test(a)
}

function getScrollBarWidth() {
	var c = document.createElement("p");
	c.style.width = "100%";
	c.style.height = "200px";
	var d = document.createElement("div");
	d.style.position = "absolute";
	d.style.top = "0px";
	d.style.left = "0px";
	d.style.visibility = "hidden";
	d.style.width = "200px";
	d.style.height = "150px";
	d.style.overflow = "hidden";
	d.appendChild(c);
	document.body.appendChild(d);
	var b = c.offsetWidth;
	d.style.overflow = "scroll";
	var a = c.offsetWidth;
	if (b == a) {
		a = d.clientWidth
	}
	document.body.removeChild(d);
	return (b - a)
}

function createCookie(a, b, c) {
	if (c) {
		var e = new Date();
		e.setTime(e.getTime() + (c * 24 * 60 * 60 * 1000));
		var d = "; expires=" + e.toGMTString()
	} else {
		var d = ""
	}
	document.cookie = a + "=" + b + d + "; path=/"
}

function readCookie(f) {
	var b = f + "=";
	var e = document.cookie.split(";");
	for (var a = 0; a < e.length; a++) {
		var d = e[a];
		while (d.charAt(0) == " ") {
			d = d.substring(1, d.length)
		}
		if (d.indexOf(b) == 0) {
			return d.substring(b.length, d.length)
		}
	}
	return null
}

function eraseCookie(a) {
	createCookie(a, "", -1)
}

function getCookie(d) {
	var b = d + "=";
	var a = document.cookie.split(";");
	for (var e = 0; e < a.length; e++) {
		var f = a[e];
		while (f.charAt(0) == " ") {
			f = f.substring(1)
		}
		if (f.indexOf(b) == 0) {
			return f.substring(b.length, f.length)
		}
	}
	return ""
};


"svg use".replace(/\w+/g, function (a) {
	document.createElement(a)
});
/MSIE\s[678]\b/.test(navigator.userAgent) && document.attachEvent("onreadystatechange", function () {
	for (var d = document.getElementsByTagName("use"), c = 0, a; a = d[c]; ++c) {
		var b = new Image();
		b.src = a.getAttribute("xlink:href").replace("#", ".") + ".png";
		a.parentNode.replaceChild(b, a)
	}
});
/Trident\/[567]\b/.test(navigator.userAgent) && document.addEventListener("DOMContentLoaded", function () {
	[].forEach.call(document.querySelectorAll("use"), function (b) {
		var c = b.parentNode,
			d = b.getAttribute("xlink:href");
		if (d) {
			var e = new XMLHttpRequest(),
				a = document.createElement("x");
			e.open("GET", d.replace(/#.+$/, ""));
			e.onload = function () {
				a.innerHTML = e.responseText;
				c.replaceChild(a.querySelector(d.replace(/.+#/, "#")), b)
			};
			e.send()
		}
	})
});
var Form = {
	Email: function () {
		if (!String.prototype.trim) {
			(function () {
				var c = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
				String.prototype.trim = function () {
					return this.replace(c, "")
				}
			})()
		}[].slice.call(document.querySelectorAll(".input__field")).forEach(function (c) {
			if (c.value.trim() !== "") {
				classie.add(c.parentNode, "input--filled")
			}
			c.addEventListener("focus", a);
			c.addEventListener("blur", b)
		});

		function a(c) {
			classie.add(c.target.parentNode, "input--filled")
		}

		function b(c) {
			if (c.target.value.trim() === "") {
				classie.remove(c.target.parentNode, "input--filled")
			}
		}
		$("#newsletter_form").validate({
			ignore: ".ignore",
			rules: {
				newsletterName: {
					required: true,
				},
				newsletterEmail: {
					required: true,
					email: true
				}
			},
			messages: {
				newsletterName: {
					required: "Please enter your name",
				},
				newsletterEmail: {
					required: "Please enter your email",
					email: "Please enter valid email address"
				}
			},
			submitHandler: function (c) {
				$.ajax({
					type: "POST",
					url: "../php/newsletter_form.php",
					data: $("#newsletter_form").serialize(),
					success: function (d) {}
				});
				c.submit()
			}
		});
		$("#downloadSampleReport").validate({
			ignore: ".ignore",
			rules: {
				name: {
					required: true,
				},
				phone: {
					required: true,
					number: true,
					minlength: 7,
					maxlength: 15
				},
				email: {
					required: true,
					email: true
				},
				hiddenRecaptcha: {
					required: function () {
						if (grecaptcha.getResponse() == "") {
							return true
						} else {
							return false
						}
					}
				}
			},
			messages: {
				name: {
					required: "Please enter your name",
				},
				phone: {
					required: "Please enter your phone",
					number: "Please enter valid phone number"
				},
				email: {
					required: "Please enter your work email",
					email: "Please enter valid email address"
				},
				hiddenRecaptcha: {
					required: "Please verify reCAPTCHA"
				}
			},
			submitHandler: function (c) {
				$(".sa_Modal").removeClass("is-open");
				c.submit()
			}
		});
		$("#startProjectForm").validate({
			ignore: ".ignore",
			rules: {
				first_name: {
					required: true,
				},
				last_name: {
					required: true,
				},
				email: {
					required: true,
					email: true
				},
				mobile: {
					required: true,
					number: true,
					minlength: 7,
					maxlength: 15
				},
				services: {
					required: true,
				},
				hiddenRecaptcha: {
					required: function () {
						if (grecaptcha.getResponse() == "") {
							return true
						} else {
							return false
						}
					}
				}
			},
			messages: {
				first_name: {
					required: "Please enter your first name",
				},
				last_name: {
					required: "Please enter your last name",
				},
				email: {
					required: "Please enter your email",
					email: "Please enter valid email address"
				},
				mobile: {
					required: "Please enter your mobile",
					number: "Please enter valid mobile number"
				},
				services: {
					required: "Please select service"
				},
				hiddenRecaptcha: {
					required: "Please verify reCAPTCHA"
				}
			},
			submitHandler: function (c) {
				c.submit()
			}
		})
	},
	init: function () {
		this.Email()
	}
};
var dispatch = {
	headerSticky: function () {
		var a = $(window).scrollTop();
		if (a != 0) {
			$("#header").addClass("sticky")
		}
		$(window).scroll(function () {
			var b = $(window).scrollTop();
			if (b != 0) {
				$("#header").addClass("sticky")
			} else {
				$("#header").removeClass("sticky")
			}
		})
	},
	mainNav: function () {
		$(".menuBtn a").click(function (a) {
			a.preventDefault();
			$(this).toggleClass("is-active");
			if ($(this).hasClass("is-active")) {
				disableScroll();
				$(".menuWrapper").addClass("showMenu");
				$("body").addClass("nav-active");
				$("body, #header").addClass("lockScroll").css("marginRight", getScrollBarWidth())
			} else {
				enableScroll();
				$(".menuWrapper").removeClass("showMenu");
				$("body").removeClass("nav-active");
				$("body, #header").removeClass("lockScroll").css("marginRight", 0)
			}
		});
		$(".closeMenu").click(function (a) {
			a.preventDefault();
			$(".menuWrapper").removeClass("showMenu");
			$("body, #header").removeClass("lockScroll").css("marginRight", 0)
		});
		if ($(window).width() < 768) {
			$(".toggle-menu").click(function () {
				$(this).next(".dropdown").slideToggle("fast");
				$(".dropdown").not($(this).next()).slideUp("fast");
				return false
			})
		}
	},
	slickSlider: function () {
		$('.hero-slider').on('init', function(event, slick){
			var dots = $( '.slick-dots li' );
			dots.each( function( k, v){
			  $(this).find( 'button' ).addClass( 'heading'+ k );
			});
			var items = slick.$slides;
			items.each( function( k, v){
				var text = $(this).find( 'h2' ).text();
				$( '.heading' + k ).text(text);
			});
		});
        $('.hero-slider').slick({
			autoplay:true,
			draggable: true,
			arrows: false,
			dots: true,
			fade: true,
			autoplaySpeed: 6000,
			speed: 500,
			infinite: true,
			cssEase: 'linear',
			touchThreshold: 100
		})


		

        let breakSlider = $('.break-slider').slick({
			autoplay:true,
			draggable: true,
			arrows: true,
			dots: true,
			fade: true,
			autoplaySpeed: 6000,
			speed: 500,
			infinite: true,
			cssEase: 'linear',
			touchThreshold: 100
		})
		$('.break__slider').append('<div class="pagingInfo"><span class="number">1</span><span class="totalNumber"></span></div>')
			var $statusNumber = $('.pagingInfo .number');
			var $statusTotal = $('.pagingInfo .totalNumber');
			var $slickElement = $('.break-slider');

		$slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
			var i = (currentSlide ? currentSlide : 0) + 1;
			$statusNumber.text(i);
			$statusTotal.text(slick.slideCount);
		});
		
		// our mySlider dots
		let dots = $('.break-slider .slick-dots > li', breakSlider);
		
		

		// before mySlider change slick event
		breakSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		
			// remove dot past classes on dots after next slide dot
			$(dots[nextSlide]).nextAll().removeClass('past');
			
			// add dot past classes on dots before next slide dot
			$(dots[nextSlide]).prevAll().addClass('past');
			
		});
		
        
		$('.industry-slider').slick({
			autoplay:false,
			draggable: true,
			arrows: true,
			dots: false,
			slidesToShow: 2,
			slidesToScroll: 1,
			autoplaySpeed: 3000,
			speed: 500,
			infinite: false,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
				  }
				},
				{
				  breakpoint: 550,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				  }
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			  ]
		});

		
		$('.achieve-slider').on('init', function(event, slick){
			var dots = $( '.achieve-slider .slick-dots li' );
			dots.each( function( k, v){
			  $(this).find( 'button' ).addClass( 'achive-heading'+ k );
			});
			var items = slick.$slides;
			items.each( function( k, v){
				var text = $(this).find( 'h2' ).text();
				$( '.achive-heading' + k ).text(text);
			});
		});
		$('.achieve-slider').slick({
			autoplay:false,
			draggable: false,
			arrows: true,
			dots: true,
			fade: true,
			speed: 100,
			infinite: true,
		});

		$('.news-slider').slick({
			autoplay:false,
			draggable: true,
			arrows: false,
			dots: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplaySpeed: 3000,
			speed: 500,
			infinite: false,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
				  }
				},
				{
				  breakpoint: 550,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				  }
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			  ]
		});
		/* new home js end */





		$('#bankSlider').slick({
			dots: false,
			focusOnSelect: false,
			infinite: false,
			arrows: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
		});

		$('#approach__slider').on('init', function(event, slick){
			var dots = $( '.approach__slider .slick-dots li' );
			dots.each( function( k, v){
			  $(this).find( 'button' ).addClass( 'heading1'+ k );
			});
			var items = slick.$slides;
			items.each( function( k, v){
				var text = $(this).find( 'h4' ).text();
				$( '.heading1' + k ).text(text);
			});
		});
		$('#approach__slider').slick({
			dots: true,
			focusOnSelect: false,
			infinite: false,
			arrows: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
		});


		$('#partnerSlider').on('init', function(event, slick){
			var dots = $( '#partnerSlider .slick-dots li' );
			dots.each( function( k, v){
			  $(this).find( 'button' ).addClass( 'heading'+ k );
			});
			var items = slick.$slides;
			items.each( function( k, v){
				var text = $(this).find( 'h2' ).text();
				$( '.heading' + k ).text(text);
			});
		});
		$('#partnerSlider').slick({
			dots: true,
			focusOnSelect: false,
			infinite: false,
			arrows: false,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
		});
		
		
		$('#testimonialSlider').slick({
			dots: false,
			focusOnSelect: true,
			infinite: false,
			arrows: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: true,
			centerPadding: '0',
		});

		

		$('#acquireSlider').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: false,
			autoplay: false,
			autoplaySpeed: 2000,
			dots: false,
			arrows: true,
			responsive: [
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, 
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
		
		
		$('#appreciationSlider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 2000,
			dots: true,
			arrows: false
		});

		$('#omnichannelSlider').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: false,
			autoplay: false,
			autoplaySpeed: 2000,
			dots: false,
			infinite:false,
			arrows: true,
			responsive: [
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, {
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		
		$('#diffrentSlider').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: false,
			autoplay: false,
			autoplaySpeed: 2000,
			dots: false,
			arrows: true,
			responsive: [
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, {
					breakpoint: 401,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		
		
		$('#loyaltySlider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 2000,
			dots: true,
			arrows: false
		});
		
		$('#solutionSlider').slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			infinite: false,
			autoplay: false,
			autoplaySpeed: 2000,
			dots: false,
			arrows: true,
			responsive: [
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, {
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		$('#pannelSlider').slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 2000,
			dots: false,
			arrows: true,
			responsive: [
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, {
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		$('#dateSlider').slick({
			slidesToShow: 6,
			slidesToScroll: 1,
			autoplay: true,
			infinite: true,
			autoplaySpeed: 2000,
			dots: false,
			arrows: true,
			responsive: [
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, {
					breakpoint: 401,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		$('#reactSlider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			infinite: false,
			autoplaySpeed: 5000,
			dots: true,
			arrows: false,
		});

		
		$('#skills-slider').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			infinite: true,
			autoplaySpeed: 2000,
			dots: false,
			arrows: true,
			responsive: [
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, {
					breakpoint: 401,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		$('#ourExperienceSlider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			infinite: true,
			autoplaySpeed: 2000,
			dots: false,
			arrows: false,
		});

		

		
		$('.pwa-dev-slider').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 2000,
			dots: false,
			arrows: true,
			responsive: [
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, {
					breakpoint: 401,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
		$('.landing-service__slider').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			dots: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, {
					breakpoint: 401,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
		$('.industry-tab-slider').slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 2000,
			responsive: [
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, {
					breakpoint: 401,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
		$('.project-slider').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 2000,
			responsive: [
				{
					breakpoint: 801,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}, {
					breakpoint: 401,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});


		$("#pop-up-slider").slick({
			dots: false,
			arrows: false,
			infinite: true,
			autoplay : true ,
			autoplaySpeed : 0,
			speed:4000,
			slidesToShow: 4,
			vertical: false,
			slidesToScroll: 1,
			cssEase: 'linear',
			variableWidth: true,
			responsive: [
				{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				}
				},
				{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					centerMode: true,
					
				}
				}
			]

		});
		$("#timelineSlider").slick({
			dots: false,
			arrows: true,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			vertical: true,
			slidesToScroll: 1
		});
		$("#timelineSlider").on("beforeChange", function (b, a, d, c) {
			$(".historyNav li").removeClass("current");
			$(".historyNav li").eq(c).addClass("current")
		});
		$(".historyNav li").click(function () {
			var a = $(this).index();
			$("#timelineSlider").slick("slickGoTo", parseInt(a))
		});
		$("#agileApproach").slick({
			dots: false,
			arrows: true,
			infinite: false,
			speed: 400,
			slidesToShow: 1,
			slidesToScroll: 1
		});
		$("#agileApproach").on("beforeChange", function (b, a, d, c) {
			$(".aligna_Nav li").removeClass("active");
			$(".aligna_Nav li").eq(c).addClass("active")
		});
		$(".aligna_Nav li").click(function () {
			var a = $(this).index();
			$("#agileApproach").slick("slickGoTo", parseInt(a))
		});
		$("#whySA_slider").slick({
			dots: true,
			arrows: true,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 801,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 601,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			}]
		});
		$("#testimonialSlider").slick({
			dots: true,
			arrows: false,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1
		});
		$("#glimpseProject_qa").slick({
			fade: true,
			dots: true,
			arrows: false,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1
		});
		$("#csSlider").slick({
			dots: true,
			arrows: false,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1
		});
		$("#csHomeSlider").slick({
			dots: false,
			arrows: true,
			infinite: false,
			speed: 300,
			slidesToShow: 3.8,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 1025,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 801,
				settings: {
					slidesToShow: 2.4,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 601,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 401,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}]
		});
		$("#nrSlider").slick({
			dots: true,
			fade: true,
			arrows: false,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
		});
		$("#webinarSlider").slick({
			dots: false,
			arrows: true,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow: $(".prevArr"),
			nextArrow: $(".nextArr")
		});
		$("#pr_Slider").slick({
			dots: false,
			arrows: true,
			infinite: false,
			speed: 300,
			slidesToShow: 2,
			slidesToScroll: 1,
			prevArrow: $(".prevArr_pr"),
			nextArrow: $(".nextArr_pr")
		});
		$("#rp_slider").slick({
			dots: true,
			arrows: true,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1
		});
		$("#newVideoSlider").slick({
			dots: true,
			arrows: false,
			infinite: false,
			speed: 300,
			slidesToShow: 2,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 769,
				settings: {
					slidesToShow: 1.8,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 601,
				settings: {
					slidesToShow: 1.2,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 401,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}]
		});
		
		$('.initCS').on('init', function(event, slick){
			$("#hoverDir .slick-track > .csBox").each(function () {
				$(this).hoverdir()
			});
		});
		
		$(".initCS").slick({
			dots: true,
			arrows: false,
			infinite: false,
			speed: 300,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 1025,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 801,
				settings: {
					slidesToShow: 1.8,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 601,
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 401,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}]
		});

		$("#init_innovate").slick({
			dots: false,
			fade: false,
			arrows: true,
			infinite: false,
			speed: 300,
			slidesToShow: 2.5,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1367,
					settings: {
						slidesToShow: 1.8,
					}
				},
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 481,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		});
	},
	
	plugins: function () {
		$(".openPop").on("click", function () {
			disableScroll();
			var popId = $(this).attr('data-pop');
			$(popId).addClass("is-open");
			return false
		});
		$(".closePop").on("click", function () {
			enableScroll();
			$(this).parents('.popUps').removeClass("is-open");
			return false
		});
		// rangr slider start
		// var mergingTooltipSlider = document.getElementById('slider-range');
		// noUiSlider.create(mergingTooltipSlider, {
		// 	start: [3000, 20000],
		// 	connect: true,
		// 	tooltips: [true, true],
		// 	range: {
		// 		'min': 0,
		// 		'max': 50000
		// 	},
		// 	format: wNumb({
		// 		decimals: 0,
		// 		mark: '.',
		// 		thousand: ',',
		// 		prefix: '$ ',
		// 	})
		// });
		// rangr slider end

		$(".dateSlider a").click(function(){
			$(".dateSlider a").removeClass('active');
			$(this).addClass('active');
		});

		$(".solutionType .solutionType__block").each(function () {
			$(this).hoverdir()
		});
		$("#hoverDir > .csBox").each(function () {
			$(this).hoverdir()
		});
		$("#csHomeSlider .cshomeSlide").each(function () {
			$(this).hoverdir()
		});
		$("#tabs").tabs();
		
		$(".scrollto").click(function () {
			if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
				var b = $(this.hash);
				b = b.length ? b : $("[name=" + this.hash.slice(1) + "]");
				if (b.length) {
					$("html,body").animate({
						scrollTop: b.offset().top - 80
					}, 400);
					return false
				}
			}
		});
		$("#typed_home").typed({
			stringsElement: $("#typed-strings"),
			typeSpeed: 20,
			backSpeed: 50,
			backDelay: 400,
			loop: false,
		});
		var a;
		$("#accordion_career li:first").find(".detailBox").show();
		$("#accordion_career li").click(function () {
			a = $(this);
			$("#accordion_career li").removeClass("active");
			$(this).addClass("active");
			$(this).siblings("li").find(".detailBox").hide();
			$("> .detailBox", this).show("fast");
			setTimeout(function () {
				var b = a.offset().top - 100;
				$("body, html").animate({
					scrollTop: b
				}, 400)
			}, 10)
		});
		var block = false;
		$(".solutionType").mouseenter(function(){
		if(!block) {
			block = true;
			console.log('enter');
			$(".solutionType__block").removeClass('active', function(){
				block = false;
			});
		}
		});
		$(".solutionType").mouseleave(function(){
		if(!block) {
			block = true;
			console.log('enter');
			$(".solutionType__block:first-child").addClass('active', function(){
				block = false;
			});
		}
		});

		$(".backToTop").click(function () {
			$("body, html").animate({
				scrollTop: 0
			}, 600)
		});

		

		$(".openModal").on("click", function () {
			disableScroll();
			$(".customModal, .playerContainer").addClass("is-open");
			return false
		});
		$(".closeModal").on("click", function () {
			enableScroll();
			$(".customModal, .playerContainer").removeClass("is-open");
			return false
		});
		$(".open_spForm").on("click", function () {
			var ww = $(window).width();
			if(ww >= 768) {
				disableScroll();
			}
			$(".startProject").fadeIn();
			return false;
		});
		$(".close_sa_popup, .close_sa_popup2").on("click", function () {
			enableScroll();
			$(this).parents(".startProject").fadeOut();
			return false
		});
		$(".startProject").on("click", function () {
			enableScroll();
			$(this).fadeOut();
			return false
		});
		$(".innerContainer").on("click", function (e) {
			e.stopPropagation();
			enableScroll();
		});
		$(document).keyup(function (b) {
			if (b.keyCode == 27) {
				enableScroll();
				$(".startProject").fadeOut()
			}
		})
	},
	LoadAnimation: function () {
		$(".animated").appear(function () {
			var b = $(this);
			var c = b.data("animation");
			if (!b.hasClass("visible")) {
				var a = b.data("animation-delay");
				if (a) {
					setTimeout(function () {
						b.addClass(c + " visible")
					}, a)
				} else {
					b.addClass(c + " visible")
				}
			}
		})
	},
	init: function () {
		this.headerSticky();
		this.mainNav();
		this.slickSlider();
		this.plugins();
		this.LoadAnimation()
		console.log('App call')
	}
};
var Loader = {
	el: $(".loader"),
	progressEl: $(".loader .progress"),
	updateProgress: function (a) {
		this.progressEl.css({
			width: a + "%"
		})
	},
	hide: function () {
		Loader.el.addClass("hidden");
		$("body").removeClass("hidden")
	}
};
var assetsCounterMax = 1;
var updateLoadedAssetsCounter = function (a) {
	loadedAssetsCounter++;
	var b = parseInt(assetsCounterMax * 100);
	if (b.toString().length == 1) {
		b = "  " + b
	} else {
		if (b.toString().length == 2) {
			b = " " + b
		}
	}
	Loader.updateProgress(b);
	if (loadedAssetsCounter == assetsCounterMax) {
		setTimeout(function () {
			Loader.hide()
		}, 0)
	}
};
var loadJsAssets = function (a) {
	$.ajax({
		url: getRootUrl() + "js/libs-combined.min.js",
		dataType: "script",
		cache: false
	})
	.done(function () {
		updateLoadedAssetsCounter("javascript");
		a()
	})
};
$(function () {
	// loadJsAssets(function () {
	// 	dispatch.init();
	// 	Form.init()
	// })
	dispatch.init();
	Form.init()
});